import { template as template_b74d51bdf1fb44cf8f3f4c93b6c08471 } from "@ember/template-compiler";
const SidebarSectionMessage = template_b74d51bdf1fb44cf8f3f4c93b6c08471(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
