import { template as template_461cc943192244fca24d63138f8909ec } from "@ember/template-compiler";
const FKLabel = template_461cc943192244fca24d63138f8909ec(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
